import { combineReducers } from '@reduxjs/toolkit';
import { reducer as userReducer } from 'src/slices/user';
import { reducer as usersReducer } from 'src/slices/users';
import accountingUnitReducer from 'src/slices/accountingUnit';
import treasuryReducer from 'src/slices/treasury';
import expenditureClassificationReducer from 'src/slices/expenditureClassification';
import backdropState from 'src/slices/backdropState';

const rootReducer = combineReducers({
    user: userReducer,
    users: usersReducer,
    accountingUnit: accountingUnitReducer,
    expenditureClassification: expenditureClassificationReducer,
    treasury: treasuryReducer,
    backdrop: backdropState
});

export default rootReducer;
