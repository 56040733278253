import axios from 'axios';
import { api } from 'src/utils/axios';
class UsersService {
    async getCurrentIdentityServerUser() {
        const res = await axios.get('/auth/userinfo');
        return res.data;
    }

    async getCurrentUser() {
        const res = await api.get('/current_user');
        return res.data;
    }

    async getUsers({ params } = {}) {
        const res = await api.get('/users', { params });
        return { users: res.data, count: parseInt(res.headers.totalcount) };
    }

    async getOne({ userId }) {
        const res = await api.get(`/users/${userId}`);
        return res.data;
    }

    async search({ email }) {
        const res = await api.get('/users/search_to_onboard', { params: { email } });
        return res.data;
    }

    async create({ data }) {
        const res = await api.post('/users', data);
        return res.data;
    }

    async edit({ userId, data } = {}) {
        const res = await api.put(`/users/${userId}`, data);
        return res.data;
    }
}
export default new UsersService();
