import React, { useState } from 'react';
import { IconButton, SvgIcon, Tooltip, Menu, MenuItem } from '@mui/material';
import { useSnackbar } from 'notistack';
import { LANGUAGES } from 'src/constants';
import LanguageTwoToneIcon from '@mui/icons-material/LanguageTwoTone';
import { useLanguage } from 'src/hooks/useLanguage';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import i18n from 'src/i18n';

const Languages = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { changeLanguage } = useLanguage();
    const [anchorEl, setAnchorEl] = useState(null);
    const { t } = useTranslation();
    const user = useSelector((state) => state.user.currentUser);
    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLanguageChange = (newLanguage) => {
        if (newLanguage && newLanguage !== user?.preferred_language) {
            changeLanguage(
                newLanguage,
                () => {
                    enqueueSnackbar(t('header.alerts.languageChanged'), {
                        variant: 'info'
                    });
                },
                () => {
                    enqueueSnackbar(t('header.alerts.errorChangingLanguage'), {
                        variant: 'error'
                    });
                }
            );
        }
        handleClose();
    };

    return (
        <>
            <Tooltip arrow title={t('header.languages')}>
                <IconButton color="primary" onClick={handleOpen}>
                    <SvgIcon>
                        <LanguageTwoToneIcon fontSize="large" />
                    </SvgIcon>
                </IconButton>
            </Tooltip>

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                {Object.keys(LANGUAGES).map((language) => (
                    <MenuItem
                        key={language}
                        onClick={() => handleLanguageChange(language)}
                        selected={language === user?.preferred_language || language === i18n.language}
                    >
                        {LANGUAGES[language]}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default Languages;
