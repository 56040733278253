export const kurdishTranslation = {
    header: {
        languages: 'زمان',
        alerts: {
            languageChanged: 'زمان گۆڕدرا',
            errorChangingLanguage: 'هەڵەیەک ڕوویدا لە زمان گۆڕین'
        }
    },
    general: {
        active: 'چالاک',
        inactive: 'ناچالاک',
        all: 'هەموو',
        of: 'لە',
        centralized: 'مەڵبەندی',
        decentralized: 'نامەڵبەندی',
        rows_per_page: 'ڕیز لە پەڕە',
        yes: 'بەڵێ',
        no: 'نەخێر',
        legacyIdError: 'کۆدی پێشتر داواکراوە وە پێویستە ژمارە بێت',
        version: 'وەشانی',
        currentVersion: 'v1.1.3',
        ReleaseNotes: 'تێبینی بڵاوکردنەوە'
    },

    view: {
        common: {
            filter: {
                label: 'فلتەر',
                reset: 'بەتاڵکردنەوەی فلتەر'
            }
        }
    },

    pages: {
        users: {
            title: 'بەکارهێنەرەکان',
            subTitle: 'زیادکردن و دەستکاریکردنی بەکارهێنەرەکان',
            name: 'ناو',
            email: 'ئیمەیڵ',
            preferredLanguage: 'زمانی خوازراو',
            status: 'دۆخ',
            createdAt: 'بەرواری دروستکردن',
            roles: 'ڕۆڵەکان',
            systemUsers: 'بەکارهێنەرەکان',
            userError: 'ئەم بەکارهێنەرە لەناو سیستمدا بوونی هەیە',
            fullName: 'ناوی سیانی',
            searchUsers: 'گەڕان ',
            editUser: 'دەستکاریکردنی بەکارهێنەر',
            editSystemUser: 'دەستکاریکردنی بەکارهێنەر',
            cancel: 'هەڵوەشاندنەوه',
            actions: {
                addUsers: 'زیادکردنی بەکارهێنەر',
                actions: 'کردارەکان',
                userAdded: 'بەکارهێنەر زیاد کرا',
                addingUserFailed: 'هەڵەیەک ڕوویدا لەکاتی زیادکردنی بەکارهێنەر',
                userEdit: 'زانیاری دەستکاری کرا',
                EditingUserFailed: 'هەڵەیەک ڕوویدا لەکاتی گۆڕینی زانیاری'
            }
        },
        treasury: {
            tabTitle: 'گەنجینەکان',
            title: 'گەنجینە',
            treasury: 'گەنجینە',
            detail: 'وردەکاری',
            treasuryDetails: ' وردەکاری گەنجینە',
            tableTitle: 'گەنجینەکان',
            name: 'ناو',
            new: 'گەنجینەی نوێ',
            ckbTitle: 'ناوی کوردی',
            enTitle: 'ناوی ئینگلیزی',
            arTitle: 'ناوی عەرەبی',
            id: 'کۆد',
            legacyId: 'کۆدی پێشتر',
            treasuryId: 'کۆد',
            status: 'دۆخ',
            centrality: 'مەڵبەندی',
            createdAt: 'بەرواری دروستکردن',
            accountingUnits: 'یەکەی ژمێریاری',
            action: 'کردار',
            noAccountingUnit: 'گەنجینە هیچ یەکەیەکی ژمێریاری نییە',
            noAccountingUnitWithFilter: 'هیچ یەکەیەکی ژمێریاری بەم دۆخەوە نییە',

            actions: {
                addTreasury: 'زیادکردنی گەنجینە',
                createSuccessful: 'گەنجینە دروستکرا',
                createFailed: 'هەڵەیەک ڕوویدا لە دروست کردنی گەنجینە',
                create: 'دروستکردن',
                update: 'نوێکردنەوە',
                updateSuccessful: 'گەنجینە نوێکرایەوە',
                updateFailed: 'هەڵەیەک ڕوویدا لە دەستکاری کردنی گەنجینە',
                editTreasury: 'دەستکاریکردنی گەنجینە',
                createTreasury: 'دروستکردنی گەنجینە',
                back: 'گەڕانەوە'
            }
        },

        accountingUnits: {
            accountingUnits: 'یەکەی ژمێریاری',
            name: 'ناو',
            createdAt: 'بەرواری دروستکردن',
            status: 'دۆخ',
            ckbTitle: 'ناوی کوردی',
            enTitle: 'ناوی ئینگلیزی',
            arTitle: 'ناوی عەرەبی',
            treasury: 'گەنجینە',
            entity: 'ئۆرگانی حکومی',
            legacyId: 'کۆدی پێشتر',
            accountingDetail: 'زانیارییەکانی یەکەی ژمێریاری',
            newAccountingUnits: 'یەکەی ژمێریاری نوێ',
            id: 'کۆد',
            edit: 'دەستکاریکردنی یەکەی ژمێریاری',
            create: 'دروستکردنی یەکەی ژمێریاری',
            coded_number: 'کۆدی ئەکاونت',
            children: 'منداڵ',
            legacy_id: 'کۆدی پێشتر',
            filterId: 'ناسنامەی ڕەسەن',
            parent: 'باوان',
            centrality: 'مەڵبەندی',
            actions: {
                addAccounting: 'دروستکردنی یەکەی ژمێریاری',
                createSuccessful: 'یەکەی ژمێریاری دروستکرا',
                createFailed: 'هەڵەیەک ڕوویدا لە دروست کردنی یەکەی ژمێریاری',
                create: 'دروستکردن',
                update: 'نوێکردنەوە',
                updateSuccessful: 'یەکەی ژمێریاری نوێکرایەوە',
                updateDuplicateIdFailed: ' یەکەی ژمێریاری بونی هەیە بەم کۆد پێشتر',
                updateFailed: 'هەڵەیەک ڕوویدا لە دەستکاری کردنی یەکەی ژمێریاری',
                editAccounting: 'دەستکاریکردنی یەکەی ژمێریاری'
            }
        },
        expenditureClassifications: {
            expenditureClassifications: 'جۆەرەکانی خەرجی',
            expenditureProfile: 'زانیاری جۆری خەرجی',
            editExpenditure: 'دەستکاریکردنی جۆری خەرجی',
            newExpenditure: 'جۆری خەرجی نوێ',
            name: 'ناو',
            accountCode: 'کۆدی ئەکاونت',
            createdAt: 'بەرواری دروستکردن',
            status: 'دۆخ',
            ckbTitle: 'ناوی کوردی',
            enTitle: 'ناوی ئینگلیزی',
            arTitle: 'ناوی عەرەبی',
            treasury: 'گەنجینە',
            entity: 'ئۆرگانی حکومی',
            legacyId: 'کۆدی پێشتر',
            expenditureParent: 'باوانی جۆری خەرجی',
            isSelectable: 'هەڵدەبژێردرێت',
            parent: 'باوان',
            id: 'کۆد',
            children: 'وەچەکان',
            hasNoChildren: 'جۆری خەرجی هیچ وەچەیەکی نییە',
            isSelectableInfo: 'زانیاری',
            actions: {
                addExpenditure: 'دروستکردنی جۆری خەرجی',
                createSuccessful: 'جۆری خەرجی دروستکرا',
                createFailed: 'هەڵەیەک ڕوویدا لە دروست کردنی جۆری خەرجی',
                create: 'دروستکردن',
                update: 'نوێکردنەوە',
                updateSuccessful: 'جۆری خەرجی نوێکرایەوە',
                updateFailed: 'هەڵەیەک ڕوویدا لە دەستکاری کردنی جۆری خەرجی',
                goTo: 'بڕۆ بۆ',
                edit: 'دەستکاریکردنی جۆری خەرجی'
            }
        },
        sidebar: {
            management: 'بەڕێوەبردن',
            accountingUnits: 'یەکەی ژمێریاری',
            treasury: 'گەنجینە',
            expenditureClassifications: 'جۆری خەرجی',
            settings: 'ڕێکخستنەکان',
            users: 'بەکارهێنەرەکان',
            profile: 'زانیاریی بەکارهێنەر',
            about: 'دەربارە',
            releaseNote: 'ناوەڕۆکی گۆرانکارییەکان'
        },
        userProfile: {
            name: 'ناو',
            profile: 'پرۆفایل',
            personalData: ' زانیاری بەکارهێنەر',
            givenName: 'ناو',
            familyName: 'ناوی باوک',
            email: 'ئیمەیڵ',
            status: 'دۆخ',
            preferredLanguage: 'زمانی خوازراو',
            roles: 'ڕۆڵەکان',
            signOut: 'چوونەدەرەوە'
        },
        login: {
            financialAccounts: 'هەژماری دارایی ',
            loginInToYourAccount: 'چوونە ژوورەوە بۆ ناو هەژمارەکەت',
            detail: 'سیستەمی هەژماری دارایی داوای پشتڕاستکردنەوە دەکات. ئەگەر هەژمارێکی چالاکت هەیە، ئاڕاستە دەکرێیت بۆ ناو هەژمارەکەت',
            login: 'چوونەژوورەوە'
        },
        roles: {
            superAdmin: 'سەرپەرشتیاری نایاب',
            treasuriesManager: 'بەڕێوەبەری گەنجینە',
            expenditureClassificationsManager: 'بەڕێوەبەری جۆری خەرجی',
            accountingUnitsManager: 'بەڕێوەبەری یەکەی ژمێریاری',
            accountingUnitsMapper: 'نەخشەکێشی یەکەی ژمێریاری ',
            usersManager: 'بەڕێوەبەری بەکارهێنەر',
            manager: 'بەڕێوەبەر',
            observer: 'چاودێر'
        }
    },

    notifications: {
        unauthorized: {
            title: 'ڕێگەپێنەدراوە',
            message: 'مۆڵەتی پێویستت نییە بۆ بەکارهێنانی ئەم تایبەتمەندییە، تکایە پەیوەندی بە بەڕێوەبەرەوە بکە'
        }
    },

    actions: {
        submit: 'پێشکەشکردن'
    },

    languages: {
        kurdish: 'کوردی',
        english: 'ئینگلیزی',
        arabic: 'عەرەبی'
    }
};
