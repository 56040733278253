export const rolesAndPermissions = {
    super_admin: {
        users_sidebar: true,
        treasuries_sidebar: true,
        release_notes_sidebar: true,
        accounting_units_sidebar: true,
        expenditure_classifications_sidebar: true
    },
    treasuries_manager: {
        treasuries_sidebar: true
    },
    accounting_units_manager: {
        accounting_units_sidebar: true
    },

    accounting_units_mapper: {
        accounting_units_sidebar: true
    },
    users_manager: {
        users_sidebar: true
    },
    expenditure_classifications_manager: {
        expenditure_classifications_sidebar: true
    },
    observer: {
        treasuries_sidebar: true,
        accounting_units_sidebar: true
    }
};
