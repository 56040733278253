import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import SuspenseLoader from 'src/components/SuspenseLoader';
import { useSelector } from 'react-redux';

import { LinearProgress } from '@mui/material';

const Loader = (Component) => (props) =>
    (
        <Suspense fallback={<SuspenseLoader />}>
            <Component {...props} />
        </Suspense>
    );

// Applications

const ReleaseNotes = Loader(lazy(() => import('src/views/releaseNotes')));
const Users = Loader(lazy(() => import('src/views/Users')));
const UserProfile = Loader(lazy(() => import('src/views/UserProfile')));
const EditUser = Loader(lazy(() => import('src/views/Users/EditUser')));
const CreateUser = Loader(lazy(() => import('src/views/Users/CreateUser')));

const Login = Loader(lazy(() => import('src/views/auth/index')));

// Treasuries
const Treasuries = Loader(lazy(() => import('src/views/Treasuries/all')));
const TreasuryProfile = Loader(lazy(() => import('src/views/Treasuries/profile/index')));
const CreateTreasury = Loader(lazy(() => import('src/views/Treasuries/create/index')));
const EditTreasury = Loader(lazy(() => import('src/views/Treasuries/edit')));

// Accounting Units
const AccountingUnits = Loader(lazy(() => import('src/views/accountingUnit/all')));
const AccountingUnitProfile = Loader(lazy(() => import('src/views/accountingUnit/profile')));
const EditAccountingUnit = Loader(lazy(() => import('src/views/accountingUnit/edit')));
const CreateAccountingUnit = Loader(lazy(() => import('src/views/accountingUnit/create')));

// Expenditure Classifications
const ExpenditureClassifications = Loader(lazy(() => import('src/views/expenditureClassification/all')));
const ExpenditureClassificationProfile = Loader(lazy(() => import('src/views/expenditureClassification/profile')));
const EditExpenditureClassification = Loader(lazy(() => import('src/views/expenditureClassification/edit')));
const CreateExpenditureClassification = Loader(lazy(() => import('src/views/expenditureClassification/create')));

// Status

const Status404 = Loader(lazy(() => import('src/content/pages/Status/Status404')));
const Status403 = Loader(lazy(() => import('src/content/pages/Status/Status403')));

const ProtectedRoute = ({ children }) => {
    const { isAuthenticated, isAuthenticationLoading } = useSelector((state) => state.user);
    if (isAuthenticationLoading) return <LinearProgress />;

    return isAuthenticated ? children : <Navigate to={'/login-page'} />;
};

const LoginRouteWrapper = ({ children }) => {
    const { isAuthenticated, isAuthenticationLoading } = useSelector((state) => state.user);
    if (isAuthenticationLoading) return <LinearProgress />;
    return isAuthenticated ? <Navigate to="/" /> : children;
};

const routes = [
    {
        path: '*',
        element: <BaseLayout />,

        children: [
            {
                path: '*',
                element: <Status404 />
            },
            {
                path: '/login-page',
                element: (
                    <LoginRouteWrapper>
                        <Login />
                    </LoginRouteWrapper>
                )
            },
            {
                path: '/403',
                element: <Status403 />
            }
        ]
    },
    {
        path: '*',
        element: (
            <ProtectedRoute>
                <SidebarLayout />
            </ProtectedRoute>
        ),

        children: [
            {
                path: '/',
                element: (
                    <ProtectedRoute>
                        <Navigate to="/profile" replace />
                    </ProtectedRoute>
                )
            },
            // accounting-units
            {
                path: '/accounting-units',
                element: (
                    <ProtectedRoute>
                        <AccountingUnits />
                    </ProtectedRoute>
                )
            },
            {
                path: '/accounting-units/create',
                element: (
                    <ProtectedRoute>
                        <CreateAccountingUnit />
                    </ProtectedRoute>
                )
            },
            {
                path: '/accounting-units/profile/:id',
                element: (
                    <ProtectedRoute>
                        <AccountingUnitProfile />
                    </ProtectedRoute>
                )
            },
            {
                path: '/accounting-units/profile/:id/edit',
                element: (
                    <ProtectedRoute>
                        <EditAccountingUnit />
                    </ProtectedRoute>
                )
            },
            // expenditure-classifications
            {
                path: '/expenditure-classifications',
                element: (
                    <ProtectedRoute>
                        <ExpenditureClassifications />
                    </ProtectedRoute>
                )
            },
            {
                path: '/expenditure-classifications/create',
                element: (
                    <ProtectedRoute>
                        <CreateExpenditureClassification />
                    </ProtectedRoute>
                )
            },
            {
                path: '/expenditure-classifications/profile/:id',
                element: (
                    <ProtectedRoute>
                        <ExpenditureClassificationProfile />
                    </ProtectedRoute>
                )
            },
            {
                path: '/expenditure-classifications/profile/:id/edit',
                element: (
                    <ProtectedRoute>
                        <EditExpenditureClassification />
                    </ProtectedRoute>
                )
            },
            {
                path: '/treasuries',
                element: (
                    <ProtectedRoute>
                        <Treasuries />
                    </ProtectedRoute>
                )
            },
            {
                path: 'treasury/:id',
                element: (
                    <ProtectedRoute>
                        <TreasuryProfile />
                    </ProtectedRoute>
                )
            },
            {
                path: '/treasuries/create',
                element: (
                    <ProtectedRoute>
                        <CreateTreasury />
                    </ProtectedRoute>
                )
            },
            {
                path: '/users',
                element: (
                    <ProtectedRoute>
                        <Users />
                    </ProtectedRoute>
                )
            },
            {
                path: '/users/create/:id',
                element: (
                    <ProtectedRoute>
                        <CreateUser />
                    </ProtectedRoute>
                )
            },
            {
                path: '/users/edit/:id',
                element: (
                    <ProtectedRoute>
                        <EditUser />
                    </ProtectedRoute>
                )
            },
            {
                path: '/EditTreasury/:id',
                element: (
                    <ProtectedRoute>
                        <EditTreasury />
                    </ProtectedRoute>
                )
            },
            {
                path: '/profile',
                element: (
                    <ProtectedRoute>
                        <UserProfile />
                    </ProtectedRoute>
                )
            },
            {
                path: '/release-notes',
                element: (
                    <ProtectedRoute>
                        <ReleaseNotes />
                    </ProtectedRoute>
                )
            }
        ]
    }
];

export default routes;
