import axios from 'axios';
import i18n from 'src/i18n';

const api = axios.create({
    baseURL: '/api/v1'
});

const navigateToLoginPage = () => {
    if (window.location.pathname !== '/login-page') {
        window.location.href = '/login-page';
    }
};

const navigateTo403Page = () => {
    window.location.href = '/403';
};

api.interceptors.request.use(
    async (config) => {
        config.params = { ...config.params, locale: i18n.language };
        return config;
    },
    (error) => Promise.reject(error)
);

api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (401 === error?.response?.status) {
            navigateToLoginPage();
        } else if (403 === error?.response?.status) {
            navigateTo403Page();
        } else {
            return Promise.reject(error);
        }
    }
);

export { api };
