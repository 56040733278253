import App from './App';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import * as serviceWorker from './serviceWorker';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import 'nprogress/nprogress.css';
import { SidebarProvider } from './contexts/SidebarContext';
import store from './store';
import './i18n';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: "https://bc9030374f6d46fbb7c0c744eb330425@sentry.dev.krd/23",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.5,
  });
}

ReactDOM.render(
    <HelmetProvider>
        <Provider store={store}>
            <SidebarProvider>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </SidebarProvider>
        </Provider>
    </HelmetProvider>,
    document.getElementById('root')
);

serviceWorker.unregister();
