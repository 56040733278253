import { Box, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const LogoWrapper = styled(Link)(
    ({ theme }) => `
        color: ${theme.palette.text.primary};
        padding: ${theme.spacing(0, 1, 0, 0)};
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
`
);

const LogoTextWrapper = styled(Box)(
    ({ theme }) => `
        padding-left: ${theme.spacing(1)};
`
);

const VersionBadge = styled(Box)(
    ({ theme }) => `
        background: ${theme.palette.info.dark};
        color: ${theme.palette.success.contrastText};
        padding: ${theme.spacing(0.3, 1)};
        border-radius: ${theme.general.borderRadiusSm};
        text-align: center;
        display: inline-block;
        font-size: ${theme.typography.pxToRem(13)};
`
);

const LogoText = styled(Box)(
    ({ theme }) => `
        font-size: ${theme.typography.pxToRem(15)};
        font-weight: ${theme.typography.fontWeightBold};
`
);

function Logo() {
    const { t } = useTranslation();
    return (
        <LogoWrapper to="/profile">
            <LogoTextWrapper>
                <Tooltip title={t('general.currentVersion')} arrow placement="right">
                    <VersionBadge>{t('general.currentVersion')}</VersionBadge>
                </Tooltip>
                <LogoText>{t('pages.login.financialAccounts')}</LogoText>
            </LogoTextWrapper>
        </LogoWrapper>
    );
}

export default Logo;
