import i18n from 'i18next';
import languageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { englishTranslation } from './locales/en';
import { kurdishTranslation } from './locales/ckb';

const fallbackLng = ['en'];
const availableLanguages = ['en', 'ckb'];

const options = {
    // order and from where user language should be detected
    order: ['localStorage', 'querystring'],

    // keys or params to lookup language from
    lookupQuerystring: 'locale',
    lookupLocalStorage: 'locale',
    lookupSessionStorage: 'locale',
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,

    // cache user language on
    caches: ['localStorage'],
    excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

    // optional expire and domain for set cookie
    cookieMinutes: 10,
    cookieDomain: 'myDomain',

    // optional htmlTag with lang attribute, the default is:
    htmlTag: document.documentElement,

    // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
    cookieOptions: { path: '/', sameSite: 'strict' }
};

// the translations
const resources = {
    en: {
        translation: englishTranslation
    },
    ckb: {
        translation: kurdishTranslation
    }
};

i18n.use(languageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        // lng: "en",
        detection: options,
        fallbackLng, // use en if detected lng is not available
        whitelist: availableLanguages,

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
