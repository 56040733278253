import { useRoutes } from 'react-router-dom';
import routes from './router';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import UsersService from './services/UsersService';
import { CssBaseline } from '@mui/material';
import { useCallback, useEffect } from 'react';

import { setIsAuthenticatingLoading, setUser, setIdentityServerUser } from './slices/user';
import { useDispatch, useSelector } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { getBackdropState } from 'src/slices/backdropState';

import { StylesProvider } from '@mui/styles';
import { ThemeProvider } from '@mui/material/styles';
import createTheme from 'src/theme/schemes/PureLightTheme';
import CustomBackdrop from 'src/components/Backdrop/Index';

import * as yup from 'yup';
import { yupCkb } from 'src/utils/yupCkb';
import { yupEn } from 'src/utils/yupEn';
import i18n from 'i18next';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import Fade from '@mui/material/Fade';

import './assets/css/custom.css';

// Create rtl cache
const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [rtlPlugin]
});

const RtlProvider = ({ dir, children }) => {
    if (dir === 'rtl') return <CacheProvider value={cacheRtl}>{children}</CacheProvider>;
    else return children;
};

const getDirection = () => (i18n.language === 'en' ? 'ltr' : 'rtl');

const App = () => {
    const dispatch = useDispatch();
    const openBackdrop = useSelector(getBackdropState);

    const getIdentityServerUser = useCallback(async () => {
        try {
            const user = await UsersService.getCurrentIdentityServerUser();
            dispatch(setIdentityServerUser(user));
        } catch (err) {
            console.error(err);
        }
    }, []);

    const getUser = useCallback(async () => {
        try {
            const currentUser = await UsersService.getCurrentUser();
            dispatch(setUser(currentUser));
        } catch (err) {
            dispatch(setIsAuthenticatingLoading(false));
            console.error(err);
        }
    }, []);

    useEffect(() => {
        getUser();
        getIdentityServerUser();
    }, []);

    const routing = useRoutes(routes);

    const theme = createTheme({
        direction: getDirection()
    });

    useEffect(() => {
        document.body.dir = getDirection();
    }, [i18n.language]);

    useEffect(() => {
        if (i18n.language === 'ckb') yup.setLocale(yupCkb);
        if (i18n.language === 'en') yup.setLocale(yupEn);
        else yup.setLocale({});
    }, [i18n.language]);

    return (
        <StylesProvider injectFirst>
            <RtlProvider dir={getDirection()}>
                <ThemeProvider theme={theme}>
                    <SnackbarProvider
                        dense
                        maxSnack={3}
                        preventDuplicate
                        TransitionComponent={Fade}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}
                    >
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <CustomBackdrop open={openBackdrop} /> <CssBaseline />
                            {routing}
                        </LocalizationProvider>
                    </SnackbarProvider>
                </ThemeProvider>
            </RtlProvider>
        </StylesProvider>
    );
};
export default App;
