import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import UsersService from 'src/services/UsersService';

const userSlice = createSlice({
    name: 'user',
    initialState: {
        currentUser: null,
        identityServerUser: null,
        isAuthenticated: false,
        isAuthenticationLoading: true
    },
    reducers: {
        setUser(state, action) {
            state.currentUser = action.payload;
            state.isAuthenticated = true;
            state.isAuthenticationLoading = false;
        },

        setIdentityServerUser(state, action) {
            state.identityServerUser = action.payload;
        },

        logout(state, action) {
            state.currentUser = null;
            state.isAuthenticated = false;
        },

        setIsAuthenticatingLoading(state, action) {
            state.isAuthenticationLoading = action.payload;
        }
    }
});

export const { reducer } = userSlice;

export const { setUser, setIdentityServerUser, setIsAuthenticatingLoading } = userSlice.actions;

export const useCurrentUser = () => {
    return useSelector((state) => state.user.currentUser);
};

export const useAuthentication = () => {
    return useSelector((state) => state.user.isAuthenticated);
};

export const updateCurrentUserLanguage = async (preferredLanguage, currentUser, dispatch) => {
    const updatedUser = await UsersService.edit({
        userId: currentUser.id,
        data: {
            user: {
                preferred_language: preferredLanguage
            }
        }
    });
    await dispatch(userSlice.actions.setUser(updatedUser));
};
