export const yupCkb = {
    mixed: {
        default: 'field_invalid',
        required: 'داواکراوە',
        defined: 'پێویستە پێناسەکراو بێت'
    },

    string: {
        min: 'دەبێت بە لایەنی کەمەوە ${min} کاراکتەر بێت',
        max: 'دەبێت بە لایەنی زۆرەوە ${max} کاراکتەر بێت',
        email: 'پێویستە لە جۆری ئیمەیڵی دروست بێت'
    },

    number: {
        min: 'پێویستە زیاتر یاخود یەکسان بێت بە 4{min}',
        max: 'پێویستە کەمتر یاخو یەکسان بێت بە ${max}',
        lessThan: 'پێویستە کەمتر بێت لە ${less}',
        moreThan: 'پێویستە زیاتر بێت لە ${more}',
        positive: 'پێویستە ژمارەی پۆزەتیڤ بێت',
        negative: 'پێویستە ژمارەی نێگەتیڤ بێت',
        integer: 'پێویستە لە جۆری ژمارە بێت'
    }
};
