import { Box } from '@mui/material';
import Languages from './Languages';

function HeaderButtons() {
    return (
        <Box sx={{ mr: 1 }}>
            <Box sx={{ mx: 0.5 }} component="span">
                <Languages />
            </Box>
        </Box>
    );
}

export default HeaderButtons;
