import i18n from 'i18next';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateCurrentUserLanguage } from 'src/slices/user';
import { setBackdropState } from 'src/slices/backdropState';

export const useLanguage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user.currentUser);

    useEffect(() => {
        if (currentUser) {
            if (i18n.language !== currentUser?.preferred_language) {
                i18n.changeLanguage(currentUser?.preferred_language);
            }
        }
    }, [currentUser]);

    const changeLanguage = async (language, onChange, onError) => {
        dispatch(setBackdropState(true));
        try {
            currentUser && (await updateCurrentUserLanguage(language, currentUser, dispatch));
            i18n.changeLanguage(language);
            onChange();
            navigate(0);
        } catch (err) {
            onError();
        } finally {
            dispatch(setBackdropState(false));
        }
    };

    return { changeLanguage };
};
