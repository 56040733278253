import { rolesAndPermissions } from './rolesAndPermissions';

export const hasAccessTo = ({ permission, roles, permissions }) => {
    let hasAccess = false;

    if (permission) {
        for (const role of roles) {
            if (rolesAndPermissions[role] && rolesAndPermissions[role][permission]) {
                hasAccess = true;
                break;
            }
        }
    } else if (permissions) {
        for (const permission of permissions) {
            if (hasAccessTo({ permission, roles })) {
                hasAccess = true;
                break;
            }
        }
    }

    return hasAccess;
};
