import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import AccountBalanceTwoToneIcon from '@mui/icons-material/AccountBalanceTwoTone';
import BusinessTwoToneIcon from '@mui/icons-material/BusinessTwoTone';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';

const menuItems = ({ t }) => [
    {
        heading: t('pages.sidebar.management'),
        permissions: ['accounting_units_sidebar', 'treasuries_sidebar', 'expenditure_classifications_sidebar'],
        items: [
            {
                name: t('pages.sidebar.accountingUnits'),
                icon: BusinessTwoToneIcon,
                link: '/accounting-units',
                permission: 'accounting_units_sidebar'
            },
            {
                name: t('pages.sidebar.treasury'),
                icon: AccountBalanceTwoToneIcon,
                link: '/treasuries',
                permission: 'treasuries_sidebar'
            },
            {
                name: t('pages.sidebar.expenditureClassifications'),
                icon: LocalAtmIcon,
                link: '/expenditure-classifications',
                permission: 'expenditure_classifications_sidebar'
            }
        ]
    },
    {
        heading: t('pages.sidebar.settings'),
        items: [
            {
                name: t('pages.sidebar.users'),
                icon: PeopleAltTwoToneIcon,
                link: '/users',
                permission: 'users_sidebar'
            },
            {
                name: t('pages.sidebar.profile'),
                icon: AccountCircleTwoToneIcon,
                link: '/profile'
            }
        ]
    },
    {
        heading: t('pages.sidebar.about'),
        permissions: ['release_notes_sidebar'],
        items: [
            {
                name: t('pages.sidebar.releaseNote'),
                icon: SummarizeOutlinedIcon,
                link: '/release-notes',
                permission: 'release_notes_sidebar'
            }
        ]
    }
];

export default menuItems;
