export const yupEn = {
    mixed: {
        default: 'field_invalid',
        required: 'required',
        defined: ' Must be defined',
        matches: 'Must match'
    },

    string: {
        min: 'Must be at least ${min} characters',
        max: 'Must be at most ${max} characters',
        email: 'Must be a valid email address'
    },

    number: {
        min: '${min} Must be equal or greater than 4',
        max: ' Must be equal or less than ${max}',
        lessThan: 'Must be less than ${less}',
        moreThan: 'Must be more than ${more}',
        positive: 'Number should be positive',
        negative: 'Number should be negative',
        integer: 'Must be an integer'
    }
};
