import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
    name: 'backdrop',
    initialState: {
        open: false
    },

    reducers: {
        setBackdropState: (state, action) => {
            state.open = action.payload;
        }
    }
});

export const { setBackdropState } = slice.actions;
export default slice.reducer;

export const getBackdropState = (state) => state.backdrop.open;
