import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function CustomBackdrop({ handleClose, open }) {
    return (
        <div>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => 999999 }} open={open} onClick={handleClose}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}
