import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
    name: 'treasury',

    initialState: {
        tableState: {
            pageIndex: 0,
            pageSize: 10,
            sortBy: [{ id: 'id', desc: false }],
            filters: []
        },
        currentGlobalTreasury: {}
    },

    reducers: {
        setPageIndex: (state, action) => {
            state.tableState.pageIndex = action.payload;
        },
        setPageSize: (state, action) => {
            state.tableState.pageSize = action.payload;
        },
        setSortBy: (state, action) => {
            state.tableState.sortBy = action.payload;
        },
        setFilter: (state, action) => {
            const columnExists = state.tableState.filters.some((column) => column.id === action.payload.id);
            if (columnExists) {
                if (typeof action.payload.value === 'string' && action.payload.value.length === 0) {
                    state.tableState.filters = state.tableState.filters.filter(
                        (column) => column.id !== action.payload.id
                    );
                    return;
                }

                state.tableState.filters = state.tableState.filters.map((column) =>
                    column.id === action.payload.id ? action.payload : column
                );
            } else {
                state.tableState.filters.push(action.payload);
            }
        },
        setAllFilters: (state, action) => {
            state.tableState.filters = action.payload;
        },

        setCurrentGlobalTreasury: (state, action) => {
            state.currentGlobalTreasury = action.payload;
        }
    }
});

export const { setPageIndex, setPageSize, setSortBy, setFilter, setAllFilters, setCurrentGlobalTreasury } =
    slice.actions;
export default slice.reducer;

// Selectors
export const getPageIndex = (state) => state.treasury.tableState.pageIndex;
export const getPageSize = (state) => state.treasury.tableState.pageSize;
export const getSortBy = (state) => state.treasury.tableState.sortBy;
export const getFilters = (state) => state.treasury.tableState.filters;
export const getCurrentGlobalTreasury = (state) => state.treasury.currentGlobalTreasury;
