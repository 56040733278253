export const englishTranslation = {
    header: {
        languages: 'Languages',
        alerts: {
            languageChanged: 'Language changed',
            errorChangingLanguage: 'Error changing language'
        }
    },
    general: {
        active: 'Active',
        inactive: 'Inactive',
        all: 'All',
        of: 'Of',
        centralized: 'Centralized',
        decentralized: 'Decentralized',
        rows_per_page: 'Rows Per Page',
        yes: 'Yes',
        no: 'No',
        legacyIdError: 'Legacy ID is required and must be a number',
        version: 'Version',
        currentVersion: 'v1.1.3',
        ReleaseNotes: 'Release Notes'
    },

    view: {
        common: {
            filter: {
                label: 'Filters',
                reset: 'Reset'
            }
        }
    },
    pages: {
        users: {
            title: 'Users',
            subTitle: 'You can add and edit system users here',
            name: 'Name',
            email: 'Email',
            preferredLanguage: 'Preferred Language',
            status: 'Status',
            createdAt: 'Created At',
            roles: 'Roles',
            systemUsers: 'System Users',
            userError: 'This user already exists in the system',
            fullName: ' Full Name',
            searchUsers: 'Search Users',
            editUser: 'Edit User',
            editSystemUser: 'Edit System User',
            cancel: 'Cancel',
            actions: {
                addUsers: 'Add User',
                actions: 'Actions',
                userAdded: 'User Added',
                addingUserFailed: 'Something went wrong while adding user',
                userEdit: 'User Edited',
                EditingUserFailed: 'Something went wrong while editing user'
            }
        },
        treasury: {
            tabTitle: 'Treasuries',
            title: 'Treasuries',
            treasury: 'Treasury',
            detail: 'Details',
            treasuryDetails: 'Treasury Details',
            tableTitle: 'The Treasuries',
            name: 'Name',
            new: 'New Treasury',
            ckbTitle: 'Kurdish name',
            enTitle: 'English name',
            arTitle: 'Arabic name',
            id: 'ID',
            legacyId: 'Legacy ID',
            treasuryId: 'Treasury Id',
            status: 'Status',
            centrality: 'Centrality',
            createdAt: 'Created at',
            accountingUnits: 'Accounting Units',
            action: 'Action',
            noAccountingUnit: 'Treasury has no accounting units',
            noAccountingUnitWithFilter: 'No accounting unit with this status',

            actions: {
                addTreasury: 'Add Treasury',
                createSuccessful: 'Treasury Created',
                createFailed: 'Something went wrong in adding the treasury',
                create: 'Create',
                update: 'Update',
                updateSuccessful: 'Treasury Updated',
                updateFailed: 'Something went wrong in adding the treasury',
                editTreasury: 'Edit Treasury',
                createTreasury: 'Create Treasury',
                back: 'Back'
            }
        },
        accountingUnits: {
            accountingUnits: 'Accounting Unit',
            name: 'Name',
            createdAt: 'Created at',
            status: 'Status',
            ckbTitle: ' Kurdish Name',
            enTitle: 'English Name ',
            arTitle: 'Arabic Name ',
            treasury: 'Treasury',
            entity: 'Entity',
            legacyId: 'Legacy ID',
            accountingDetail: 'Accounting Unit Details',
            newAccountingUnits: 'New Accounting Units',
            id: 'ID',
            edit: 'Edit Accounting Unit',
            create: 'Create Accounting Unit',
            coded_number: 'Account Code',
            legacy_id: 'Legacy ID',
            filterId: 'Original ID',
            parent: 'Parent',
            centrality: 'Centrality',
            actions: {
                addAccounting: ' Add Accounting unit',
                createSuccessful: 'Accounting unit Created',
                createFailed: 'Something went wrong in creating Accounting unit',
                create: 'Create',
                update: 'Update',
                updateSuccessful: 'Updated Accounting Unit',
                updateFailed: 'Something went wrong in updating Accounting unit',
                updateDuplicateIdFailed: 'An Accounting unit already exists with this legacy id',
                editAccounting: 'Edit'
            }
        },
        expenditureClassifications: {
            expenditureClassifications: 'Expenditure Classifications',
            expenditureProfile: 'Expenditure Classification Profile',
            editExpenditure: 'Edit Expenditure ',
            newExpenditure: 'New Expenditure Classification',
            name: 'Name',
            accountCode: 'Account Code',
            createdAt: 'Created at',
            status: 'Status',
            ckbTitle: ' Kurdish Name',
            enTitle: 'English Name',
            arTitle: 'Arabic Name',
            treasury: 'Treasury',
            entity: 'Entity',
            legacyId: 'Legacy ID',
            expenditureParent: 'Expenditure Classification Parent',
            isSelectable: 'Is Selectable',
            parent: 'Parent',
            id: 'ID',
            children: 'Children',
            hasNoChildren: 'Expenditure Classification has no children',
            isSelectableInfo: 'Info',

            actions: {
                addExpenditure: 'Add Expenditure Classification',
                createSuccessful: 'Expenditure Classification Created',
                createFailed: 'Something went wrong in creating Expenditure Classification',
                create: 'Create',
                update: 'Update',
                updateSuccessful: 'Updated Expenditure Classification',
                updateFailed: 'Something went wrong in updating Expenditure Classification',
                goTo: 'Go to',
                edit: 'Edit'
            }
        },
        sidebar: {
            management: 'Management',
            accountingUnits: 'Accounting Unit',
            treasury: 'Treasury',
            expenditureClassifications: 'Expenditure Classifications',
            settings: 'Settings',
            users: 'Users',
            profile: 'Profile',
            about: 'About',
            releaseNote: 'Release Note'
        },
        userProfile: {
            name: 'Name',
            profile: 'Profile',
            personalData: 'Personal Data',
            givenName: 'Given Name',
            familyName: 'Family Name',
            email: 'Email',
            status: 'Status',
            preferredLanguage: 'Preferred Language',
            roles: 'Roles',
            signOut: 'Sign out'
        },
        login: {
            financialAccounts: 'Financial Accounts',
            loginInToYourAccount: 'Log into your account',
            detail: 'Financial Accounts is requesting authentication. If you have an active account, you will be redirected to log in to your account.',
            login: 'Login'
        },
        roles: {
            superAdmin: 'Super Admin',
            treasuriesManager: 'Treasuries Manager',
            accountingUnitsManager: 'Accounting Units Manager',
            expenditureClassificationsManager: 'Expenditure Classifications Manager',
            accountingUnitsMapper: 'Accounting Units Mapper',
            usersManager: 'Users Manager',
            manager: 'Manager',
            observer: 'Observer'
        }
    },

    notifications: {
        unauthorized: {
            title: 'Unauthorized',
            message: 'You do not have the required permissions to use this feature, please contact an administrator'
        }
    },

    actions: {
        submit: 'Submit'
    },

    languages: {
        kurdish: 'Kurdish',
        english: 'English',
        arabic: 'Arabic'
    }
};
