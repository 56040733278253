import { createSlice } from '@reduxjs/toolkit';

const usersSlice = createSlice({
    name: 'users',
    initialState: {
        userToAdd: null
    },
    reducers: {
        setUserToAdd(state, action) {
            state.userToAdd = action.payload;
        }
    }
});

export const { reducer } = usersSlice;

export const { setUserToAdd } = usersSlice.actions;
